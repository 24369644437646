<template>
  <default-layout>
    <v-stack class="w-full max-w-[740px] md:text-center">
      <v-stepper
        v-if="steps > 1"
        :step="step"
        :steps="steps"
        back-button
        class="mb-36 md:mb-20"
        @back="navigateTo(backPath)"
      />

      <slot />
    </v-stack>
  </default-layout>
</template>

<script setup lang="ts">
import defaultLayout from "./default.vue"

const route = useRoute()

const steps = 1
const step = computed(() => {
  const match = route.fullPath.match(/\d+/)
  if (!match) {
    return 0
  }

  return +match[0]
})

const backPath = computed(() => {
  if (step.value <= 1) {
    return "/intro"
  }

  return `/intro/${step.value - 1}`
})
</script>
